<template>
  <div>
    <div
      class="detention-form data-row d-flex align-items-center py-3 pb-4 mb-4"
    >
      <div class="flex-1 detention-form-date">
        <strong>{{ formatedDate }}</strong>
      </div>
      <IconButton
        class="solid full-rounded"
        :icon="'ri-add-line'"
        smaller
        v-if="isCreateVisible"
        @click="openCreateTardyDetentionCalendarForm"
      >
      </IconButton>
    </div>
    <div v-for="(detention, index) in state.detentions" :key="index">
      <DetentionInfo
        :detention="{
          ...detention.detention,
          tardy_appointment_detentions_count:
            detention.tardy_appointment_detentions_count
        }"
        :detention-date="selectedDate"
        :selected-date="selectedDate"
        :is-editable-detention="!isDayInThePast()"
        @on-click-detention="onClickDetention(detention)"
      >
      </DetentionInfo>
    </div>
    <div class="text-center mt-3">
      <LoadingButton class="me-2 px-4" @click="onCancel" rounded
        >Close</LoadingButton
      >
    </div>
  </div>
</template>

<script>
import { reactive, computed, onMounted, inject } from "vue"
import { useStore } from "vuex"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton"
import IconButton from "@/v3components/shared/Buttons/IconButton.vue"
import CreateTardyDetentionCalendarForm from "@/v3components/Forms/CreateTardyDetentionCalendarForm.vue"
import DetentionInfo from "@/v3components/shared/Tardy/DetentionInfo.vue"
import moment from "moment-timezone"

export default {
  name: "DailyDetentionsForm",
  components: { LoadingButton, IconButton, DetentionInfo },
  emits: ["cancel"],
  props: {
    selectedDate: {
      type: Object,
      default: null
    }
  },
  setup(props, { emit }) {
    const state = reactive({
      detentions: []
    })
    const store = useStore()
    const modal = inject("modal")

    onMounted(() => {
      getDetentions()
      state.detentions =
        detentionsByDate.value[props.selectedDate.format("YYYY-MM-DD")]
    })

    const allDetentions = computed(
      () => store.getters["detentions/detentionsCached"]
    )
    const isCreateVisible = computed(() => {
      return isDayBeforeTomorrow()
        ? false
        : allDetentions.value && state.detentions
        ? allDetentions.value.length > state.detentions.length
        : true
    })

    const getDetentions = () => {
      store.dispatch("detentions/getDetentionsCached")
    }

    const onCancel = () => {
      emit("cancel")
    }
    const isDayInThePast = () => {
      return props.selectedDate
        ? props.selectedDate.format("YYYY-MM-DD") <
            moment().format("YYYY-MM-DD")
        : false
    }
    const isDayBeforeTomorrow = () => {
      return props.selectedDate
        ? props.selectedDate.format("YYYY-MM-DD") <
            moment().add(1, "days").format("YYYY-MM-DD")
        : false
    }
    const onClickDetention = (detention) => {
      modal.open(CreateTardyDetentionCalendarForm, {
        size: "sm",
        props: {
          selectedDate: props.selectedDate,
          editableDetention: {
            ...detention.detention,
            date_id: detention.date_id,
            label: detention.detention.name,
            value: detention.detention.id
          }
        }
      })
    }

    const formatedDate = computed(() => {
      return props.selectedDate.format("D MMM dddd")
    })

    const detentionsByDate = computed(() => {
      return store.getters["detentions/detentionsByDate"]
    })

    const openCreateTardyDetentionCalendarForm = () => {
      modal.open(CreateTardyDetentionCalendarForm, {
        size: "sm",
        props: {
          dailyDetentionsIds: state.detentions
            ? state.detentions.map((detention) => detention.detention.id)
            : null,
          selectedDate: props.selectedDate
        }
      })
    }

    return {
      state,
      onCancel,
      formatedDate,
      isCreateVisible,
      detentionsByDate,
      openCreateTardyDetentionCalendarForm,
      onClickDetention,
      isDayInThePast
    }
  }
}
</script>
