<template>
  <div class="v3-form adult-form">
    <div class="row">
      <div>
        <FileUpload
          :uploaded-file="state.form.csvFile"
          :download-example-file-path="downloadExamplePath"
          :help-text="csvInfoText"
          @update:model-value="onFileUpload"
          @remove-file="removeFile"
          :invalid-feedback="errors.csvFile || state.serverErrors.csvFile"
        >
        </FileUpload>
      </div>
      <div>
        <InfoBox
          v-if="state.serverRes"
          class="mt-4"
          :class="{ danger: Object.keys(state.serverErrors).length }"
          :title="state.serverRes.title"
          >{{ state.serverRes.message }}</InfoBox
        >
      </div>
      <div class="text-center">
        <LoadingButton class="me-2 px-4" @click="onCancel" rounded
          >Cancel</LoadingButton
        >
        <LoadingButton
          :is-loading="state.isProcessing"
          @click="submit()"
          class="mt-4 px-4"
          solid
          rounded
          >Create</LoadingButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton"
import InfoBox from "@/v3components/shared/Alerts/InfoBox"
import FileUpload from "@/v3components/shared/Form/FileUpload"
import useVuelidate from "@vuelidate/core"
import { helpers, required } from "@vuelidate/validators"
import { useStore } from "vuex"
import { reactive, computed, inject } from "vue"

export default {
  components: {
    LoadingButton,
    InfoBox,
    FileUpload
  },
  emits: ["cancel"],
  setup(props, { emit }) {
    const store = useStore()
    const modal = inject("modal")
    const state = reactive({
      isProcessing: false,
      form: {
        csvFile: null
      },
      serverErrors: {},
      serverRes: null
    })

    const downloadExamplePath =
      "/csv-samples/app-module/rooms-import-sample.csv"

    const csvInfoText =
      "Click on the “Download Example” file button to create your file or click on the blue “?” at the top of this screen for additional help as you create this CSV file."

    const onFileUpload = (event) => {
      const files = event.target.files
      if (files && files.length) {
        state.form.csvFile = files[0]
        event.target.value = ""
      }
    }
    const removeFile = () => {
      state.form.csvFile = null
    }

    const validationMessages = {
      required: "This field is required"
    }
    const validations = {
      form: {
        csvFile: {
          required: helpers.withMessage(validationMessages.required, required)
        }
      }
    }
    const v$ = useVuelidate(validations.form, state.form)

    const isFormValid = computed(() => !v$.value.$invalid)

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })

      return errorObj
    })

    const submit = () => {
      if (isFormValid.value) {
        const data = new FormData()
        data.append("csv_file", state.form.csvFile)
        createCsvFile(data)
      } else {
        v$.value.$touch()
      }
    }

    const createCsvFile = (data) => {
      state.isProcessing = true
      store
        .dispatch("rooms/importRooms", data)
        .then(() => {
          removeFile()
          setResponseInfoBox("Success", "Successfully created!")
          state.isProcessing = false
          state.serverErrors = {}
          setTimeout(() => {
            setResponseInfoBox()
            store.dispatch("rooms/getRooms")
            modal.setAction("createRoomsCsvFile")
            emit("cancel")
          }, 1800)
        })
        .catch((err) => {
          modal.setAction("createRoomsCsvFile")
          const res = err.response.data
          state.serverErrors = res.errors ? res.errors : {}
          setResponseInfoBox("Error", res.message)
          state.isProcessing = false
        })
    }

    const setResponseInfoBox = (title, message) => {
      if (title || message) {
        state.serverRes = {
          message,
          title
        }
      } else {
        state.serverRes = null
      }
    }

    const onCancel = () => {
      removeFile()
      emit("cancel")
    }

    return {
      state,
      downloadExamplePath,
      csvInfoText,
      onFileUpload,
      removeFile,
      v$,
      isFormValid,
      errors,
      submit,
      setResponseInfoBox,
      onCancel
    }
  }
}
</script>
